module.exports = new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersionABI;
    let cookies = document.cookie;
    const cookieArray = cookies.split(";").map((s) => s.split("=").map((s) => s.trim())).reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    const env = cookieArray.get("VANTAGE_ENV");
    console.log("environent ", env);
    switch (env) {
        case "local":
            remoteUrlWithVersionABI = "http://localhost:3000/remoteEntry.js";
            break;
        case "dev":
            remoteUrlWithVersionABI =
                "https://az03-hc-app-vsb-cmn-dev.azurewebsites.net/remoteEntry.js";
            break;
		case "test":
            remoteUrlWithVersionABI =
                "https://testabi-infolock.lockton.com/remoteEntry.js";
            break;
        case "Prod":
            remoteUrlWithVersionABI = "https://abi-infolock.lockton.com/remoteEntry.js";
            break;
		case "DR":
            remoteUrlWithVersionABI = "https://proddr-abi-infolock.lockton.com/remoteEntry.js";
            break;
        case "DRPROD":
            remoteUrlWithVersionABI = "https://proddr-abi-infolock.lockton.com/remoteEntry.js";
            break;	
        case "qa":
            remoteUrlWithVersionABI =
                "https://qa-exlvantage-abi.exlservice.com/remoteEntry.js";
            break;
        case "hznuat":
        case "maxoruat":
        case "demouat":
            remoteUrlWithVersionABI = "https://vantageuatabi.exlservice.com/remoteEntry.js";
            break;
        case "hznprod":
        case "maxorprod":
            remoteUrlWithVersionABI = "https://vantageprodabi.exlservice.com/remoteEntry.js";
            break;
        case "lktuat":
            remoteUrlWithVersionABI = "http://localhost:3000/remoteEntry.js";
            break;
        case "idemo":
            remoteUrlWithVersionABI = "http://localhost:3000/remoteEntry.js";
            break;
        case "lktuattest":
            remoteUrlWithVersionABI = "http://localhost:3000/remoteEntry.js";
            break;
        case "lktprod":
            remoteUrlWithVersionABI = "http://localhost:3000/remoteEntry.js";
            break;
        case "Textron":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "CHA":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "HAP":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "Idemo":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "Cdemo":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "HP":
            remoteUrlWithVersionABI = "https://vantageabireact.exlservice.com/remoteEntry.js";
            break;
        case "Maxor":
            remoteUrlWithVersionABI = "https://vantageabireactlive.exlservice.com/remoteEntry.js";
            break;
        default:
            remoteUrlWithVersionABI = "";
            break;
    }
  const script = document.createElement("script");
  script.src = remoteUrlWithVersionABI;
  script.onload = () => {
    // the injected script has loaded and is available on window
    // we can now resolve this Promise
    const proxy = {
      get: (request) => window.ABI.get(request),
      init: (arg) => {
        try {
          return window.ABI.init(arg);
        } catch (e) {
          console.log("remote container already initialized");
        }
      },
    };
    resolve(proxy);
  };
  script.onerror = () => {
    reject();
    //alert("Error loading " + this.src); // Error loading https://example.com/404.js
    document.head.appendChild(null);
    //return null;
  };
  // inject this script with the src set to the versioned remoteEntry.js
  document.head.appendChild(script);
});